.box {
    display: flex;
    flex-direction: column;
    max-width: 24em;
    min-width: 21em;
    box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    border-radius: 6px;
    padding: 24px;
}

.header {
    text-align: center;
    margin-top: 5px;
    padding: 5px;
    margin-bottom: 17px;
    /* margin-left: 35px; */
    /* margin-right: 35px; */
    font-family: Ozxgen, sans-serif;
    font-size: 22px;
    border-bottom: 2px solid;
}

.inner-container {
    transition: visibility 0.2s ease-out;
}

.selected-controller {
    transition: border 0.5s ease-out;
    border-bottom: 2px solid ;
}

.controller {
    flex: 1;
    text-align: center;
    height: 100%;
    line-height: 2;
    cursor: pointer;
}

.box-controller {
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 24em;
    min-width: 21em;
    height: 35px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    margin-bottom: 30px;
    align-items: center;
    transition: visibility 0.5s ease-out;
}


.box-title {
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    max-width: 24em;
    min-width: 21em;
    height: 50px;
    margin-top: 30px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    margin-bottom: 30px;
    transition: visibility 0.5s ease-out;
}

.btn.btn-block[disabled] {
    background-color: #8c9ba7;
}