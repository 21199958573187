//-----GLOBAL
* {
	box-sizing: border-box;
}
.clearfix{
	&:after{
		content: "";
		display: table;
		clear: both;
		height: 0;
		visibility: hidden;
	}
}


.conversation{
	height: calc(100% - 50px);
	overflow: auto;
	padding: 20px;
	padding-bottom: 0;
}

//-----MESSAGES
.screen{
	background-color: #fff;
	height: 400px;
	width: 300px;
	margin: 0 auto;
	box-shadow: 0 2px 2px rgba(0,0,0,.2);
}

.messages{
	@extend .clearfix;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
	&--received{
		.message{
			float: left;
			background-color: #ddd;
			border-bottom-left-radius: 5px;
			border-top-left-radius: 5px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      max-width: 60%;
    }
    
	}
	&--sent{
		.message{
			float: right;
			background-color: #6DBBFF;
			color: #fff;
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
			border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      max-width: 60%;
    }
	}

	&--pending{
		.message{
			float: right;
			background-color: #62B2C8;
			color: #fff;
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
			border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      max-width: 60%;
    }
	}

	&--voting{
		.message{
			float: left;
			background-color: #62B2C8;
			color: #fff;
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
			border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      max-width: 90%;
    }
	}
}

.time {
  &--received{
		float: left;
    margin-left: 10px;
    font-size: 12px;
	}
	&--sent{
		float: right;
    margin-right: 10px;
    font-size: 12px;
	}
}

.message{
	display: inline-block;
	margin-bottom: 2px;
	clear: both;
	padding: 7px 13px;
	border-radius: 15px;
	line-height: 1.4;
  font-size: 16px;
}
