code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  font-family: 'Programme';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'Programme';
  src: url(https://assets.genius.com/fonts/programme_normal.woff2?1577136005) format('woff2'),
    url(https://assets.genius.com/fonts/programme_normal.woff?1577136005) format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Programme';
  src: url(https://assets.genius.com/fonts/programme_bold.woff2?1577136005) format('woff2'),
    url(https://assets.genius.com/fonts/programme_bold.woff?1577136005) format('woff');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Programme';
  src: url(https://assets.genius.com/fonts/programme_normal_italic.woff2?1577136005) format('woff2'),
    url(https://assets.genius.com/fonts/programme_normal_italic.woff?1577136005) format('woff');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Programme';
  src: url(https://assets.genius.com/fonts/programme_light.woff2?1577136005) format('woff2'),
    url(https://assets.genius.com/fonts/programme_light.woff?1577136005) format('woff');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Programme';
  src: url(https://assets.genius.com/fonts/programme_light_italic.woff2?1577136005) format('woff2'),
    url(https://assets.genius.com/fonts/programme_light_italic.woff?1577136005) format('woff');
  font-style: italic;
  font-weight: 100;
}